import { requestApiInfoclickadu } from "../types/api";
import axios from "../utils/axios";

//--------------------------------------------------------
const url_login = "auth/signin/";
const urls_getMe = "auth/getme/";
const createApiInfoUrlAPIINFOClickadu = "api/creat-info/APIINFOClickadu/";
const updateApiInfoUrlAPIINFOClickadu = "api/creat-info/updateClickadu/";
const getApiInfoUrlAPIINFOClickadu = "api/get-info/APIINFOClickadu/";
const getProductUserApiUrl = "api/links";
const LogoutURL = "auth/logout/";
const getAPIForNameURL = "api/getPlan/";
const getAPIForNameURLForEdt = "api/EGetPlan/";

export const LoginUser = {
  login: async (data: { user_name: string; password: string }) => {
    const response = await axios.post(url_login, data);
    return response.data;
  },
  getMe: async () => {
    const response = await axios.get(urls_getMe, {});
    return response.data;
  },
};

export const createApiInfoAPIINFOClickadu = async (
  data: requestApiInfoclickadu
) => {
  const response = await axios.post(createApiInfoUrlAPIINFOClickadu, data);
  return response.data;
};
export const updateApiInfoAPIINFOClickadu = async (
  data: requestApiInfoclickadu
) => {
  const response = await axios.put(updateApiInfoUrlAPIINFOClickadu, data);
  return response.data;
};
export const getApiInfocreateApiInfo = async () => {
  const response = await axios.get(getApiInfoUrlAPIINFOClickadu, {});
  return response.data;
};

export const getProductUserApi = async (
  service_neme: boolean,
  link: boolean,
  api_key: boolean,
  order: boolean,
  params: string | null,
  orderStatus: boolean | null,
  page_size:number,
) => {
  let url: string;
  let ckeck_params = false
  if (service_neme) {
    url = getProductUserApiUrl + "?service_name=" + params;
    ckeck_params = true
  } else if (link) {
    url = getProductUserApiUrl + "?link=" + params;
    ckeck_params = true
  } else if (api_key) {
    url = getProductUserApiUrl + "?api_key=" + params;
    ckeck_params = true
  } else if(order){
    url = getProductUserApiUrl + "?order=" + params;
    ckeck_params = true
  } else url = getProductUserApiUrl;
  if (orderStatus === true  || orderStatus === false) {
    if (ckeck_params){
      url = `${url}&status=${orderStatus}`
    }else{
      url = getProductUserApiUrl + "?status=" + orderStatus;
      ckeck_params = true
    }
    
  }
  const response = await axios.get(`${url}${ckeck_params?`&page_size=${page_size}`:`?page_size=${page_size}`}`, {});
  return response.data;
};

export const getApiInfoNextPage = async (url: string) => {
  const response = await axios.get(url, {});
  return response.data;
};
export const Logout = async () => {
  const response = await axios.post(LogoutURL, {});
};

export const getApiForName = async (name: string) => {
  const response = await axios.get(`${getAPIForNameURL}?name_service=${name}`);
  return response.data;
};

export const getApiForNameEdit = async (name: string) => {
  const response = await axios.get(
    `${getAPIForNameURLForEdt}?name_service=${name}`
  );
  return response.data;
};
