// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// components
import SvgIconStyle from "../../../components/SvgIconStyle";

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const ICONS = {
  user: getIcon("ic_user"),
  home: getIcon("ic_home"),
  menuItem: getIcon("ic_menu_item"),
  setting: getIcon("setting"),
  create: getIcon("ic_create"),
  plans:getIcon("ic_plans"),
  product:getIcon("ic_product"),
  document:getIcon("ic_document"),
  newUser:getIcon("ic_new-user"),
  key:getIcon("ic_key"),
  block:getIcon("ic_block"),
  autoPause:getIcon("autoPause")
};

const navConfig = [
  // HOME
  {
    subheader: "General",
    items: [
      { title: "home", path: PATH_DASHBOARD.home, icon: ICONS.home },
      { title: "Create Service", path: PATH_DASHBOARD.create, icon: ICONS.create },
      { title: "Services", path: PATH_DASHBOARD.plans, icon: ICONS.plans },
      { title: "Orders", path: PATH_DASHBOARD.product, icon: ICONS.product },
      { title: "New user", path: PATH_DASHBOARD.newUser, icon: ICONS.newUser },
      { title: "Create Key", path: PATH_DASHBOARD.newKey, icon: ICONS.key },
      { title: "Block list", path: PATH_DASHBOARD.block, icon: ICONS.block },
      { title: "Auto pause", path: PATH_DASHBOARD.auto_pause, icon: ICONS.autoPause },
      // {
      //   title: "تنظیمات",
      //   path: PATH_DASHBOARD.setting.root,
      //   icon: ICONS.setting,
      //   children: [{ title: "تنظیمات قالب", path: PATH_DASHBOARD.setting.theme }],
      // },
    ],
  },
];

export default navConfig;
