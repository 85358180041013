function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/auth";
const ROOTS_DASHBOARD = "/dashboard";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
};

export const PATH_PAGE = {
  page403: "/403",
  page404: "/404",
  page500: "/500",
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  home: path(ROOTS_DASHBOARD, "/home"),
  document: path(ROOTS_DASHBOARD, "/document"),
  create: path(ROOTS_DASHBOARD, "/create"),
  plans: path(ROOTS_DASHBOARD, "/plans"),
  product: path(ROOTS_DASHBOARD, "/product"),
  newUser: path(ROOTS_DASHBOARD, "/new-user"),
  newKey: path(ROOTS_DASHBOARD, "/create-key"),
  block: path(ROOTS_DASHBOARD, "/block-list"),
  auto_pause: path(ROOTS_DASHBOARD, "/auto-pause"),
  setting: {
    root: path(ROOTS_DASHBOARD, "/setting"),
    theme: path(ROOTS_DASHBOARD, "/setting/theme"),
  },
};
